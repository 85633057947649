/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://l6zfmfd25vdlndkbaytydyrnzi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rjl7s7dl6vb4lbj33yowgrr4fm",
    "aws_cognito_identity_pool_id": "us-east-1:cdba20e4-1ea7-4f20-ac89-c7b4e3ba83ae",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SRl786WZi",
    "aws_user_pools_web_client_id": "1e8fbe6fksjv969ke80uo2q4qu",
    "oauth": {},
    "aws_user_files_s3_bucket": "stickers30956-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
