/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, Auth, graphqlOperation, Storage } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import awsconfig from "./aws-exports";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);
Amplify.configure(awsconfig);
Storage.configure({ level: 'protected' })

const initialState = { name: '', description: '', Image: '', Person: ''}

const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos();
    checkUser();
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }
  
  
  // async function signUp() {
  //     try {
  //         const { user } = await Auth.signUp({
              // username,
              // password,
              // attributes: {
              //     email,          // optional
              //     phone_number,   // optional - E.164 number convention
              //     // other custom attributes 
              // }
  //         });
  //         console.log(user);
  //     } catch (error) {
  //         console.log('error signing up:', error);
  //     }
  // }
  
  async function checkUser() {
    const user = await Auth.currentAuthenticatedUser();
    console.log('user: ', user);
    console.log('user attributes: ', user.attributes);
  }
  
  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  return (
    <div style={styles.container}>
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Description"
      />
      <input
        onChange={event => setInput('image', event.target.value)}
        style={styles.input}
        value={formState.image}
        placeholder="Image"
      />
      <input
        onChange={event => setInput('person', event.target.value)}
        style={styles.input}
        value={formState.person}
        placeholder="Person"
      />
      <button style={styles.button} onClick={addTodo}>Create Todo</button>
      
      <h2>Stickers</h2>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
            <p style={styles.todoPerson}>{todo.person}</p>
            <p style={styles.todoPerson}>{todo.image}</p>
          </div>
        ))
      }
      <AmplifySignOut />
    </div>
  )
}

const styles = {
  container: { margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { fontSize: 20, fontWeight: 'bold' },
  todoImage: { padding: '12px 0px', width: 50, height: 50 },
  todoPerson: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}


export default withAuthenticator(App)

// <p style={styles.todoName}>{todo.name}</p>
//                     <p style={styles.todoDescription}>{todo.description}</p>
//                     <p style={styles.todoPerson}>{todo.person}</p>
//                     <p style={styles.todoPerson}>{todo.image}</p>